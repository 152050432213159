import { ContentBlock, Typography, Button, Header } from '@/components';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

export const useStyles = createUseStyles({
  link: {
    color: 'blue'
  },
  retryButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  retryButton: {
    marginTop: 20,
    maxWidth: 200
  }
});

export const CustomerError = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const retryHandler = () => {
    navigate('/');
  };

  return (
    <>
      <Header />
      <ContentBlock>
        <Typography component="h3">
          Oops! You are requesting information that this account doesn&apos;t
          have access to.
        </Typography>
        <div className={styles.retryButtonWrap}>
          <Button
            buttonType="dark"
            text="Return to Dashboard"
            onClick={retryHandler}
            className={styles.retryButton}
          />
        </div>
      </ContentBlock>
    </>
  );
};
