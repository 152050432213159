import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
// Using HashRouter so we can get refresh correctly on AWS S3 hosting per article:  https://www.appsloveworld.com/reactjs/100/1/react-router-doesnt-work-in-aws-s3-bucket
import {
  Home,
  CreateCustomer,
  SearchCustomer,
  Customer,
  Scans,
  Scan,
  Error,
  About,
  InternetConnection,
  CustomerError
} from '@/pages';
import { Wrapper } from '@/components';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { Auth } from 'aws-amplify';

export const Router = () => {
  const {
    asset_file_version,
    behavior_list: { configurator_url, configurator_eventid_lookup_list }
  } = useContext(AssetsContext);
  const [isCorrectConfiguration, setIsCorrectConfiguration] = useState(true);
  const [userEmail, setUserEmail] = useState(null);

  const isCorrectVersion =
    asset_file_version === process.env.REACT_APP_ASSETS_FILE_VERSION;

  const checkConfiguration = async () => {
    // if the configurator url does not have the {eventId} replacement variable then we do not need to worry about look up
    if (configurator_url.search('{eventId}') == -1) return;

    const user = await Auth.currentUserInfo();
    const email = user.attributes.email;
    setUserEmail(email);
    const eventId = configurator_eventid_lookup_list[email];

    setIsCorrectConfiguration(!!eventId);
  };

  useEffect(() => {
    checkConfiguration();
  }, []);

  const isConfigurationCorrect = isCorrectVersion && isCorrectConfiguration;

  const getErrorRouter = () => {
    return (
      <Route
        index
        element={
          <Error
            emailConfigurationError={!isCorrectConfiguration}
            email={userEmail}
          />
        }
      />
    );
  };

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          {isConfigurationCorrect ? (
            <>
              <Route index element={<Home />} />
              <Route path="/createCustomer" element={<CreateCustomer />} />
              <Route
                path="/createCustomer/:customerId/:accountId?"
                element={<CreateCustomer />}
              />
              <Route path="/search" element={<SearchCustomer />} />
              <Route
                path="/customers/:customerId/:accountId?"
                element={<Customer />}
              />
              <Route
                path="/customers/scans/:customerId/:accountId?"
                element={<Scans />}
              />
              <Route
                path="/scans/:scanId/:customerId/:accountId?"
                element={<Scan />}
              />
            </>
          ) : (
            getErrorRouter()
          )}
          <Route path="/about" element={<About />} />
          <Route path="/internetConnection" element={<InternetConnection />} />
          <Route path="/customerError" element={<CustomerError />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};
